export class FillterListInvoice {
    customerId:any;
    fromDate:any;
    toDate:any;
    code:string;
    shipmentNumber:string;
    statusId:any;
    textSearch:string;
    pageNumber:number = 1;
    pageSize:number=20;
}