import { GeneralModel } from "./general.model";
import { Province } from "./province.model";
import { District } from "./district.model";
import { Ward } from "./ward.model";
import { User } from "./user.model";
import { PaymentType } from "./paymentType.model";
import { Hub } from "./hub.model";

export class Customer extends GeneralModel {
    debitDay: number;
    isPublic:boolean;
    nameEn:string;
    address:string;
    addressNote:string;
    businessLicenseNumber:string;
    email:string;
    fax:string;
    legalRepresentative:string;
    notes:string;
    parentCustomerId:number;
    phoneNumber:string;
    salesOrganizationId:number;
    salesUserId:number;
    customerStatusId:number;
    stopServiceAlertDuration:number;
    supportOrganizationId:number;
    supportUserId:number;
    taxCode:string;
    tradingName:string;
    customerTypeId:number;
    provinceId:number;
    districtId:number;
    wardId:number;
    website:string;
    workTimeId:number;
    priceListId: number;
    isShowPrice: boolean;
    lat:number;
    lng:number;
    companyFrom: string;
    companyTo: string;
    discount: number;
    hubId: number;

    province: Province;
    district: District;
    ward: Ward;
    code: string;
    userName: string;
    companyName: string;
    paymentTypeId: number;
    isMustReceiverCode2: boolean;
    //
    accountingUser: User;
    accountingUserId: number;

    salesUser: User;
    supportUser: User;
    startDate: any;
    endDate: any;
    paymentScheduleId: number;
    branch: any;
    vat: number;
    commission: number;
    addressCompany: string;
    priceListIds: number[];
    isAccept: boolean =true;
    vseOracleCode: string;
    // customerPriceList: CustomerPriceList[];
    paymentType: PaymentType;
    hub: Hub;
    isPickip: boolean;
    passWord: string;
}