import { TypeCodeHelper } from "../app/infrastructure/typeCodeHelper";

// The file contents for the current envir will overwrite these during build.
// The build system defaults to the dev envir which uses `envir.ts`, but if you do
// `ng build --env=prod` then `envir.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

let arrDomain = ["be", "tasetco", "pcspost", "vintrans", "airlineexpress", "shipnhanh",
  "vietstarexpress", "dsc", "guihangvevietnam", "bbdeliv", "chuyenphatnhanh", "giaohangbienhoa", "flashship"];
let hostname = location.hostname.split(".");
let domain = hostname.find(x => arrDomain.includes(x));

// domains name
const DOMAIN_TASETCO: string = "tasetco";
const DOMAIN_PCS: string = "pcspost";
const DOMAIN_VINTRANS: string = "vintrans";
const DOMAIN_AIRLINE: string = "airlineexpress";
const DOMAIN_SHIPNHANH: string = "shipnhanh";
const DOMAIN_VIETSTAREXPRESS: string = "vietstarexpress";
const DOMAIN_dsc: string = "dsc";
const DOMAIN_BBDELIV: string = "bbdeliv";
const DOMAIN_DELTA: string = "chuyenphatnhanh";
const DOMAIN_BE: string = "be";
const DOMAIN_GHBH: string = "giaohangbienhoa";
const DOMAIN_FLASHSHIP: string = "flashship";

var envir = {
  production: false,
  company: "SSW",
  title: "SSW",
  name: "SSW",
  namePrint: "SSW",
  unit: "",

  gMapKey: "",
  tokenFirebase: "",
  urlFirebase: "https://fcm.googleapis.com/fcm/send",

  urlLogo: "",
  postUrl: "",
  apiGeneralUrl: "",
  apiCRMUrl: "",
  apiPostUrl: "",
  apiVietstarExpress: "",
  paramsApiVietstarExpress: {
    user: "50611",
    pass: "qqqq",
    apikey: "eSg1JdaPo1k:APA91bHGaq6ilA_1N7cVh2nxUpSunxy5s0Z5IMWY70uTs5BPNrwvvhzpA2pU24cncdgBh5LHV_w6O5_ZPUUXOxwMP-ImM2Rem6ATmWMP5t3S9WotemyLKHVMq572ywK2F3nlNQwbFS89",
    data: {
      ChiNhanh: null
    }
  },

  formatDate: "",
  formatDateTable: "",
  formatTime: "",
  formatDateTime: "",
  formatDateTimeTable: "",

  centerHubLongName: "",
  centerHubSortName: "",
  poHubLongName: "",
  poHubSortName: "",
  stationHubLongName: "",
  stationHubSortName: "",

  pack: "",
  isInTong: null,

  typeRequestCode: null,
  defaultSize: 1,
  pageSize: 20,
  heightBarcode: 40,
  heightQRCode: 40,

  hub: {
    centerHubLongName: "",
    centerHubSortName: "",
    poHubLongName: "",
    poHubSortName: "",
    stationHubLongName: "",
    stationHubSortName: "",
  }

  // firebase: {
  //   apiKey: "AIzaSyDXW_MXHXMF1bnPGCtIAPEs0BMQIIXrf6U",
  //   authDomain: "dsc-demo-cb5fb.firebaseapp.com",
  //   databaseURL: "https://dsc-demo-cb5fb.firebaseio.com",
  //   projectId: "dsc-demo-cb5fb",
  //   storageBucket: "dsc-demo-cb5fb.appspot.com",
  //   messagingSenderId: "631002885321"
  // }
};
//domain
switch (domain) {
  default:
    envir.gMapKey = "AIzaSyCJ6X8dE3Igc0wUL2SIg5csH4tZrnCALLY";

    envir.namePrint = "SSW";

    envir.formatTime = "HH:mm";
    envir.formatDate = "YYYY/MM/DD";
    envir.formatDateTable = "yyyy/MM/dd";
    envir.formatDateTime = "YYYY/MM/DD HH:mm";
    envir.formatDateTimeTable = "yyyy/MM/dd HH:mm";

    envir.unit = "(kg)";

    envir.centerHubLongName = "Trung tâm";
    envir.centerHubSortName = "TT";
    envir.poHubLongName = "Chi nhánh";
    envir.poHubSortName = "CN";
    envir.stationHubLongName = "Bưu cục";
    envir.stationHubSortName = "BC";

    envir.pack = "Túi";
    envir.isInTong = true;

    envir.typeRequestCode = TypeCodeHelper.Request.NORMAL;
    envir.defaultSize = 6000;
    envir.pageSize = 20,
      envir.heightBarcode = 40;
    envir.heightQRCode = 50;

    envir.hub.centerHubLongName = "Trung tâm";
    envir.hub.centerHubSortName = "TT";
    envir.hub.poHubLongName = "Chi nhánh";
    envir.hub.poHubSortName = "CN";
    envir.hub.stationHubLongName = "Bưu cục";
    envir.hub.stationHubSortName = "BC";

    envir.urlLogo = "/assets/images/logo/ssw.png";

    envir.apiPostUrl = "https://post-api-test.ssw.vn/api";
    envir.postUrl = "https://post-api-test.ssw.vn/api";

    // // local
    //  envir.apiPostUrl = "http://localhost:55408/api";
    //  envir.postUrl = "https://post-api.vanlogexpress.vn/api";

    // dev
    // envir.apiPostUrl = "https://post-api-test.ssw.vn/api";
    // envir.postUrl = "https://post-api.vanlogexpress.vn/api";

    envir.apiGeneralUrl = envir.apiPostUrl,
    envir.apiCRMUrl = envir.apiPostUrl,

    envir.apiVietstarExpress = "http://api.vietstarexpress.net:2511/api";
    envir.tokenFirebase = "AIzaSyCVfrBxAaDm3MWP69i4tfAjbvWBRlSh0gY";
    break;

}
export const environment = envir;