import { Shipment } from "../models";
import { GeneralModel } from "../models/general.model";
export class Invoice extends GeneralModel{
    senderId:number;
    senderName:string;
    companyName:string;
    pickingAddress:string;
    taxCode:any;
    email:string;
    senderPhone:string;
    accountBank:string;
    bankName:string;
    paymentMethodId:number;
    invoiceDate:any;
    note:string;
    taxPercent:number=0;
    isSigned:boolean= false;
    dataFastCA:any;
    totalPaymentInvoice:number =0;
    totalPaymentInvoiceText:string;
    customerPaymentCode:string;
    shipments:Shipment[]=[];
    adjustReason: string;
    adjustType: number;
    isAdjust: boolean;
    isChange: boolean;
    dateCancel: any;
    dateSigned: any;
    cancelReason: string;
    seriesInvoice: string;
    codeInvoice: string;
    sendEmailFast: string;
    statusId: number;
    totalCharge:number;
}