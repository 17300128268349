import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ResponseModel } from '../models/response.model';
import { GeneralService } from './general.service';
import { PersistenceService } from 'angular-persistence';
import { environment } from '../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { MessageService } from 'primeng/components/common/messageservice';
import { ShipmentTPLPaymentFilter } from '../models/shipmentTPLPaymentFilter.model';
import { Observable } from 'rxjs/Observable';
import { TplPaymentModel } from '../view-model/tplPayment.model';


@Injectable()
export class TPLPaymentService extends GeneralService {
  constructor(protected messageService: MessageService, protected httpClient: HttpClient, protected persistenceService: PersistenceService) {
    super(messageService, httpClient, persistenceService, environment.apiCRMUrl, "TPLPayment");
  }

  async getShipmentTPLPayment(filterViewModel: ShipmentTPLPaymentFilter): Promise<ResponseModel> {
    return await super.postCustomApi("GetShipmentTPLPayment", filterViewModel).toPromise();
  }
  
  getTPLPayment(tplId?: any, dateFrom?: any,dateTo?: any,pageNum?: any,pageSize?: any){
    let params = new HttpParams();
    params = params.append("tplId", tplId);
    params = params.append("dateFrom", dateFrom);
    params = params.append("dateTo", dateTo);
    params = params.append("pageNum", pageNum);
    params = params.append("pageSize", pageSize); 
    return super.getCustomApi("GetTPLPayment", params).toPromise();
  }
  
  pay(model: TplPaymentModel) {
    return super.postCustomApi("pay", model);
  }

  async getShipmentTPLPaymentExport(filterViewModel?: ShipmentTPLPaymentFilter) {
    const res = await super.postAny("GetShipmentTPLPaymentExport", filterViewModel).toPromise();
    this.downLoadFile(res, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "BANG_KE_THANH_TOAN");
  }

  
  downLoadFile(data: any, type: string, fileName: string) {
    if (!fileName) fileName = "EXPORT_BAO_CAO";
    var a = document.createElement("a");
    document.body.appendChild(a);
    //
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    //
  }
}
