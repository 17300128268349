import { Injectable } from '@angular/core';
import { GeneralService } from "./general.service";
import { MessageService } from 'primeng/primeng';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PersistenceService } from 'angular-persistence/src/angular-persistence';

@Injectable({
  providedIn: 'root'
})
export class FastService extends GeneralService {
  constructor(protected messageService: MessageService, protected httpClient: HttpClient, protected persistenceService: PersistenceService) {
    super(messageService, httpClient, persistenceService, environment.apiGeneralUrl, "Fast");
  }

  async getPDFFAST(id:any){
    let params = new HttpParams();
    params = params.append("id", id);
    return await super.getany("getPDFFAST", params).toPromise();
  }

  async getSignPDFFAST(dataFast:any){
    let params = new HttpParams();
    params = params.append("dataFast", dataFast);
    return await super.getany("getSignPDFFAST", params).toPromise();
  }

  async createPDFFAST(model:any){
    return await super.postAny("createPDFFAST", model).toPromise();
  }


  async sendMail(dataFast:string, email:string , id: any){
    let params = new HttpParams();
    params = params.append("dataFast", dataFast);
    params = params.append("email", email);
    params = params.append("id", id);
    return await super.getCustomApi("sendEmail", params).toPromise();
  }

  async cancelInvoice(dataFast:string, reason:string,minuteNo:string,minuteDate:string, idShip:any){
    let params = new HttpParams();
    params = params.append("dataFast", dataFast);
    params = params.append("reason", reason);
    params = params.append("minuteNo", minuteNo);
    params = params.append("minuteDate", minuteDate);
    params = params.append("id", idShip);

    return await super.getCustomApi("cancelInvoice", params).toPromise();
  }

}
