import { GeneralModel } from "./general.model";
import { ListCustomerPaymentType } from "./listCustomerPaymentType.model";
import { Hub } from "./hub.model";
import { Customer } from "./index";

export class ListCustomerPayment extends GeneralModel {
    customerId: number;
    hubCreatedId: number;
    totalShipment: number;
    grandTotal: number;
    adjustPrice: number;
    note: string;
    attachmentId: number;
    listCustomerPaymentTypeId: number;
    listCustomerPaymentType: ListCustomerPaymentType;
    hubCreated: Hub;
    customer: Customer;
    beforeGrandTotal: number = 0;
    discountPercent: number = 0;
    grandTotalPrice: number = 0;
    grandTotalCOD: number = 0;
    acceptDateFrom: any;
    acceptDateTo: any;
    currentAcceptDate: any;
    userCreated: any;
    userModified: any;
    statusName: any;
    paidTotal: number = 0;
}